import { useDocumentListGridApi } from "../../../components/document-list/DocumentListGridContext";
import { useEffect } from "react";
import { RowGroupOpenedEvent } from "ag-grid-enterprise";
import { useActiveDocumentListId } from "../useActiveDocumentListId";
import { DocumentListGridApi } from "../../../types/document-list/grid.types";

type ExpandCache = {
  [documentListId: string]: {
    [rowId: string]: boolean;
  };
};

const cache: ExpandCache = {};

const getCachedIsExpanded = (columnId: string, rowId: string) => {
  return Boolean(cache[columnId]?.[rowId]);
};

export const getCachedRows = (documentListId: string) => cache[documentListId];

const setCachedIsExpanded = (
  columnId: string,
  rowId: string,
  expanded: boolean
) => {
  if (!cache[columnId]) {
    cache[columnId] = {};
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  cache[columnId]![rowId] = expanded;
};

export const setDefaultIsExpanded = (
  documentListId: string | null,
  gridApi: DocumentListGridApi
) => {
  if (!documentListId) return;

  gridApi?.forEachNode((node) => {
    if (!node.id) return;
    node.setExpanded(getCachedIsExpanded(documentListId, node.id));
  });
};

// maintain an in-memory cache of which rows are expanded so that
// we can maintain expand state when the user switches between document lists
// or closes/reopens the add documents modal
export const useHandleGroupExpandCaching = () => {
  const documentListId = useActiveDocumentListId();
  const gridApi = useDocumentListGridApi();

  useEffect(() => {
    const onExpandChanged = (event: RowGroupOpenedEvent) => {
      const rowId = event.node.id;
      if (!documentListId || !rowId) return;
      setCachedIsExpanded(documentListId, rowId, event.expanded);
    };

    gridApi?.addEventListener("rowGroupOpened", onExpandChanged);

    return () => {
      gridApi?.removeEventListener("rowGroupOpened", onExpandChanged);
    };
  }, [documentListId, gridApi]);
};
