import { first, last, uniq } from "lodash";
import { getCachedRows } from "source/hooks/document-list/grid/useHandleGroupExpandCaching";
import {
  CreateFolderRequest,
  FolderItemType,
} from "source/types/document-list/documentList.types";
import {
  DocumentListGridApi,
  DocumentListIRowNode,
} from "source/types/document-list/grid.types";
import { filterNullishValues, filterUndefined } from "../common/arrays";

export const getRootFolderName = (file_path: string | null | undefined) => {
  if (!file_path) return null;
  const trimmedPath = file_path.replace(/^\/+/, "");
  const segments = trimmedPath.split("/");

  return segments.length > 1 ? segments[0] : null;
};
/**
 * Trims the parent folder from a given file path.
 * @param path - The file path string.
 * @returns The file path string without the parent folder.
 */
export const trimParentFolder = (file_path: string) => {
  const parts = file_path?.split("/");
  return parts.slice(2).join("/");
};

export const getCachedExpandedRoutes = (
  documentListId: string,
  gridApi?: DocumentListGridApi
) => {
  return Object.entries(getCachedRows(documentListId) ?? {})?.reduce<
    string[][]
  >((acc, [k, v]) => {
    const filePath = gridApi?.getRowNode(k)?.data?.filePath;
    if (v && filePath) acc.push(filePath);
    return acc;
  }, []);
};

export const getCreateFolderParams = (
  parentNode?: DocumentListIRowNode | null,
  name?: string
): CreateFolderRequest => {
  const parentId =
    parentNode?.data?.type === FolderItemType.DOCUMENT
      ? last(parentNode?.data?.filePath) // get the closest parent id
      : parentNode?.data?.id;
  return {
    parent_id: parentId,
    name: name ?? "Untitled Folder",
  };
};

export const getCreateFolderRefreshRoute = (
  parentNode?: DocumentListIRowNode | null
): string[] => {
  const route = parentNode?.data?.filePath ?? [];
  if (parentNode?.data?.type === FolderItemType.FOLDER)
    route.push(parentNode?.data?.id);
  return filterNullishValues(uniq(route));
};
