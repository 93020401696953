import { FileRejection, FileWithPath } from "react-dropzone";
import { UploadFolderRequest } from "source/types/document-list/documentList.types";
import { chunkFilesAndDocIdsByFileSize } from "../matrix/addDocs";
import { FASTBUILD_CHUNK_SIZE_LIMIT } from "source/constants/fastBuild";
import { first } from "lodash";
import {
  removeLeadingSlashFromFilename,
  shouldCreateFolder,
} from "../common/files";
import { getRootFolderName } from "./folders";
import api from "source/api";
import { upsertToast } from "source/redux/ui";
import { pluralize } from "../common/strings";

export const validateFilesOnDrop = (
  acceptedFiles: File[],
  rejectedFiles: FileRejection[],
  dispatch: any
) => {
  // Dear DocLists team, I went ahead and added a basic error case handling here but feel free to do whatever you'd like instead
  if (!acceptedFiles.length) {
    if (rejectedFiles.length) {
      dispatch(
        upsertToast({
          id: "fastBuildUploadFailure",
          primaryText: `Unable to upload your ${pluralize(rejectedFiles.length, "file")}.`,
          secondaryText: "Make sure your files are in a supported format.",
          icon: "error",
        })
      );
    }
    return [];
  }

  return acceptedFiles;
};

/**
 * @summary UI and State agnostic function to upload a set of documents to a document list
 * @returns FastBuild document IDs
 */
export const uploadFiles = async (
  files: FileWithPath[],
  documentListId: string,
  isQuickUpload?: boolean,
  uploadFolderParams?: Partial<UploadFolderRequest>,
  onFolderCreated?: (rootPath?: string[]) => void,
  onChunkUploaded?: (
    fileChunk: File[],
    rootPath?: string[],
    paths?: string[][]
  ) => void
) => {
  const firstFile = first(files);
  const firstFilePath =
    firstFile?.path || firstFile?.webkitRelativePath || firstFile?.name;
  const createFolder = shouldCreateFolder(firstFilePath);

  let newFolderId: string | undefined;

  if (createFolder) {
    // We can check multiple places to get the root folder name if this
    // is a valid folder or just a file. Using fileupload will get us a webkitRelativePath
    // but this is not present if we do a drag and drop so we'll also check the name too
    const folderName =
      getRootFolderName(removeLeadingSlashFromFilename(firstFilePath)) ||
      "Untitled Folder";

    const newFolder = await api.documentList.createFolder(documentListId, {
      name: folderName,
      parent_id: uploadFolderParams?.rootFolderId,
      is_quick_upload: isQuickUpload,
    });
    newFolderId = newFolder.id;

    onFolderCreated && onFolderCreated(uploadFolderParams?.rootFolderPath);
  }
  // Upload files
  const { fileChunks } = chunkFilesAndDocIdsByFileSize({
    files: files,
    maxSize: FASTBUILD_CHUNK_SIZE_LIMIT,
  });
  ``;
  // Upload files concurrently
  const uploadPromises = fileChunks.map(async (fileChunk) => {
    /**
     * If dropping a folder into an existing folder, rootFolderId = new folder created
     * If dropping a document into an existing doler, rootFolderId = params.rootFolderId (i.e. the folder dropped into)
     * If dropping a folder into root, rootFolderId = new folder created,
     * If dropping a document into root, rootFolderId = undefined
     */
    const { document_ids: newDocumentIds, paths } =
      await api.documentList.uploadFolder({
        documentListId,
        files: fileChunk,
        ...uploadFolderParams,
        rootFolderId: newFolderId ?? uploadFolderParams?.rootFolderId,
      });

    // Resolve the path of the chunk to refresh
    let rootPath = uploadFolderParams?.rootFolderPath ?? [];
    if (newFolderId) {
      rootPath = [...rootPath, newFolderId];
    }

    onChunkUploaded && onChunkUploaded(fileChunk, rootPath, paths);

    return newDocumentIds; // Return new document IDs for this chunk
  });

  // Wait for all uploads to complete
  const uploadedDocumentIds = (await Promise.all(uploadPromises)).flat();

  return uploadedDocumentIds;
};

/**
 * @summary UI and State agnostic function to upload a list of URLs to a document list
 * @returns FastBuild document IDs
 */
export const uploadURLs = async (urls: string[], documentListId: string) => {
  return await api.documentList.uploadURLs(documentListId, {
    urls: urls.map((url) => ({
      url,
    })),
  });
};
