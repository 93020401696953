// Show an alert when the user tries to close the page/tab
export const handleUnloadAlert = (event: BeforeUnloadEvent) => {
  // Note that most browsers (including chrome) won't actually display our custom message
  // and will just show a generic one instead
  const message =
    "Files are still being uploaded. If you leave the page, the upload won’t be complete.";

  event.preventDefault(); // prevents navigation

  // Set returnValue to trigger the dialog
  event.returnValue = message;

  return message; // Some browsers may use this, though it's deprecated
};
