import { useSelector } from "react-redux";
import { getActiveDocumentListId } from "../../redux/documentList";
import { useDocumentListMode } from "../../components/document-list/DocumentListModeContext";
import { useRouter } from "next/router";

export const useActiveDocumentListId = (): string | null => {
  const router = useRouter();
  const { isModalMode } = useDocumentListMode();

  const reduxId = useSelector(getActiveDocumentListId);

  if (!isModalMode) {
    if (router.query.id && router.query.id !== reduxId) {
      return (
        (Array.isArray(router.query.id)
          ? router.query.id[0]
          : router.query.id) || null
      );
    }
  }

  return reduxId;
};
