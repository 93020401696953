import { useQuery } from "@tanstack/react-query";
import api from "..";
import { DocumentList } from "source/types/document-list/documentList.types";
import { documentListKeys } from "./documentListKeys";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

// Get document lists for a given org and user
export const useQueryDocumentLists = (enabled?: boolean) => {
  const enableDocumentsLists = useFeatureFlag("enableDocumentsLists");

  return useQuery({
    queryKey: documentListKeys.documentLists(),
    queryFn: async () => {
      const data = await api.documentList.getDocumentLists();

      return data as DocumentList[];
    },
    enabled: enabled && enableDocumentsLists,
  });
};
