import { queryClient } from "pages/_app";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { FileWithPath } from "react-dropzone";
import { documentListKeys } from "source/api/document-list/documentListKeys";
import { useQuickUploadsDocumentList } from "source/api/document-list/useQuickUploadsDocumentList";
import { useActiveDocumentList } from "source/hooks/document-list/useActiveDocumentList";
import { useResetDocListQueryCache } from "source/hooks/document-list/useResetDocListQueryCache";
import { useAppDispatch } from "source/redux";
import {
  addDocumentListRequests,
  clearDocumentListRequests,
  resolveDocumentListRequests,
} from "source/redux/documentLists";
import { removeToast, upsertToast } from "source/redux/ui";
import { IDocumentMime } from "source/Types";
import {
  DocumentList,
  DocumentsListStatusResponse,
  UploadFolderRequest,
} from "source/types/document-list/documentList.types";
import { handleUnloadAlert } from "source/utils/document-list/handleUnloadAlert";
import { uploadFiles, uploadURLs } from "source/utils/document-list/uploads";
import { getFastBuildAllowedMimesSet } from "source/utils/documents";
import logger from "source/utils/logger";
import { DocumentListGridContext } from "./DocumentListGridContext";
import { groupBy } from "lodash";
import { getCachedExpandedRoutes } from "source/utils/document-list/folders";

interface DocumentListUploadContextValue {
  uploadFilesToDocumentList: (
    files: FileWithPath[],
    uploadFolderParams?: Partial<UploadFolderRequest>,
    documentListId?: string
  ) => Promise<string[]>;
  // TODO: Return the document ids from this???
  uploadURLsToDocumentList: (urls: string[]) => Promise<void>;
}

export const DocumentListUploadContext =
  createContext<DocumentListUploadContextValue>({
    uploadFilesToDocumentList: () => Promise.resolve([]),
    uploadURLsToDocumentList: () => Promise.resolve(),
  });

type Props = { children: React.ReactNode };

export const DocumentListUploadContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const resetDocListQueryCache = useResetDocListQueryCache();
  const { data: activeDocumentList } = useActiveDocumentList();
  const quickUploadDocumentList = useQuickUploadsDocumentList();

  // TODO: @shirley resuscitate
  // const { refetch: refetchDocumentMetadata, isFetching } =
  //   useQueryDocumentMetadata({});

  const { gridApi: _staleGridApi } = useContext(DocumentListGridContext);

  // IMPORTANT: The grid api can become stale during the upload process so we have to maintain a ref
  const gridApiRef = useRef(_staleGridApi);
  useEffect(() => {
    gridApiRef.current = _staleGridApi;
  }, [_staleGridApi]);

  const currentDocListId =
    activeDocumentList?.id ?? quickUploadDocumentList?.id;

  const onBeforeUpload = useCallback(
    (uploadCount: number, documentListId: string) => {
      window.addEventListener("beforeunload", handleUnloadAlert);

      // Pre-processing code for active document list
      if (documentListId) {
        dispatch(
          addDocumentListRequests({
            id: documentListId,
            requestCount: uploadCount,
          })
        );

        queryClient.setQueryData(
          documentListKeys.documentListMetadata(documentListId),
          (prev) => {
            if (prev) {
              return { ...(prev as DocumentList), is_empty: false };
            }

            return prev;
          }
        );

        // Update document list count
        queryClient.setQueryData<DocumentsListStatusResponse>(
          documentListKeys.documentStatus(documentListId),
          (prev) => ({
            completed_count: prev?.completed_count ?? 0,
            total_count: (prev?.total_count ?? 0) + uploadCount,
            is_empty: prev?.is_empty ?? false,
          })
        );
      }
    },
    [dispatch]
  );

  const onAfterUpload = useCallback(
    async (documentListId: string) => {
      await resetDocListQueryCache();
      window.removeEventListener("beforeunload", handleUnloadAlert);
    },
    [resetDocListQueryCache]
  );

  const uploadFilesToDocumentList = useCallback(
    async (
      files: FileWithPath[],
      uploadFolderParams?: Partial<UploadFolderRequest>,
      documentListId = currentDocListId
    ) => {
      // Sanity check
      if (!documentListId) {
        throw new Error("Upload called without a document list ID");
      }

      const fileStatus = queryClient.getQueryData<DocumentsListStatusResponse>(
        documentListKeys.documentStatus(documentListId)
      );

      const isQuickUpload = documentListId === quickUploadDocumentList?.id;

      const allowedFiles = files.filter((file) =>
        getFastBuildAllowedMimesSet().has(file.type as IDocumentMime)
      );

      // Pre-processing code for active document list
      onBeforeUpload(allowedFiles.length, documentListId);

      // Callback performed when a folder is created
      const onFolderCreated = (rootPath?: string[]) => {
        const currGridApi = gridApiRef.current;

        if (!currGridApi && activeDocumentList?.id) {
          logger.error("gridApi is undefined");
        }

        // When a folder is created, reload the grid from the root node
        // TODO: This is only necessary right now because we have no way of knowing the grid is empty
        if (fileStatus?.total_count) {
          currGridApi?.refreshServerSide({
            purge: false,
            route: rootPath,
          });
        }
      };

      // Callback performed when each chunk resolves
      const onChunkUploaded = (
        fileChunk: File[],
        rootPath?: string[],
        paths?: string[][]
      ) => {
        const chunkLength = fileChunk.length;

        dispatch(
          resolveDocumentListRequests({
            id: documentListId,
            resolvedCount: chunkLength,
          })
        );

        const currGridApi = gridApiRef.current;

        if (!currGridApi && activeDocumentList?.id) {
          logger.error("gridApi is undefined");
        }

        // If the grid is empty, refresh it
        const rowCount = currGridApi?.getDisplayedRowCount();
        if (!rowCount) {
          currGridApi?.refreshServerSide({ purge: false });
        }

        // TODO: @shirley resuscitate
        // Start polling for metadata again
        // if (!isFetching) {
        //   refetchDocumentMetadata();
        // }

        const cachedExpandedRoutes = getCachedExpandedRoutes(
          documentListId,
          currGridApi
        );

        // If we have a folderId, refresh the grid from that folder's node
        // TODO: This won't work for nested folders, we need the api to return the folder path(s) when a file is uploaded
        if (rootPath) {
          currGridApi?.refreshServerSide({
            purge: false,
            route: rootPath,
          });
        }
        if (paths?.length) {
          paths.forEach((uploadedPath) => {
            currGridApi?.refreshServerSide({
              purge: false,
              route: uploadedPath,
            });
          });
        }
        if (cachedExpandedRoutes.length) {
          cachedExpandedRoutes.forEach((expandedRoute) => {
            currGridApi?.refreshServerSide({
              purge: false,
              route: expandedRoute,
            });
          });
        }
      };

      let fastBuiltDocIds: string[] = [];
      try {
        const groupedFiles = groupBy(allowedFiles, (file) => {
          const split = file.name.split("/");

          if (split.length < 3) {
            return "";
          }
          return split[1];
        });

        const promises = Object.entries(groupedFiles).map(
          async ([_folder, files]) => {
            return await uploadFiles(
              files,
              documentListId,
              isQuickUpload,
              uploadFolderParams,
              onFolderCreated,
              onChunkUploaded
            );
          }
        );

        const folderResults = await Promise.all(promises);
        fastBuiltDocIds = [...fastBuiltDocIds, ...folderResults.flat()];
      } catch (error: any) {
        logger.error("Error uploading files", error);

        dispatch(clearDocumentListRequests(documentListId));

        // TODO: Replace this with something better
        dispatch(
          upsertToast({
            id: "docListUploadError",
            primaryText: "Something went wrong with your upload",
            secondaryText:
              "Please try again later. Contact support@hebbia.ai if the problem persists.",
            icon: "error",
          })
        );
      }

      await onAfterUpload(documentListId);

      return fastBuiltDocIds;
    },
    [
      currentDocListId,
      activeDocumentList,
      quickUploadDocumentList,
      onBeforeUpload,
      onAfterUpload,
      dispatch,
    ]
  );

  const uploadURLsToDocumentList = async (
    urls: string[],
    documentListId = currentDocListId
  ) => {
    // Sanity check
    if (!documentListId) {
      throw new Error("Upload called without a document list ID");
    }

    dispatch(
      upsertToast({
        id: "uploadingURLs",
        primaryText: `Uploading ${urls.length} URLs`,
        icon: "info",
      })
    );

    // Pre-processing code for active document list
    onBeforeUpload(urls.length, documentListId);

    try {
      await uploadURLs(urls, documentListId);
    } catch (error: any) {
      logger.error("Error uploading urls", error);

      dispatch(clearDocumentListRequests(documentListId));

      // TODO: Replace this with something better
      dispatch(removeToast("uploadingURLs"));
      dispatch(
        upsertToast({
          id: "docListUploadError",
          primaryText: "Something went wrong with your upload",
          secondaryText:
            "Please try again later. Contact support@hebbia.ai if the problem persists.",
          icon: "error",
        })
      );
    }

    dispatch(
      resolveDocumentListRequests({
        id: documentListId,
        resolvedCount: urls.length,
      })
    );

    // Post-processing code for active document list
    await onAfterUpload(documentListId);
  };

  return (
    <DocumentListUploadContext.Provider
      value={{ uploadFilesToDocumentList, uploadURLsToDocumentList }}
    >
      {children}
    </DocumentListUploadContext.Provider>
  );
};

export const useDocumentListUpload = () =>
  useContext(DocumentListUploadContext);
