import { useQuery } from "@tanstack/react-query";
import api from "..";
import { documentListKeys } from "./documentListKeys";

export const useQueryDocumentListMetadata = (documentListId?: string) => {
  return useQuery({
    queryKey: documentListKeys.documentListMetadata(documentListId),
    queryFn: async () => {
      return api.documentList.getDocumentListMetadata(documentListId ?? "");
    },
    enabled: !!documentListId,
  });
};
