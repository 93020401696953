import { QueryContextFromKeys } from "../utils";

export const DOCUMENT_LISTS_MODULE_KEY = "documentLists";

export const documentListKeys = {
  documentListMetadata: (documentListId?: string) => [
    DOCUMENT_LISTS_MODULE_KEY,
    "documentListMetadata",
    documentListId,
  ],
  documentLists: () => [DOCUMENT_LISTS_MODULE_KEY, "listDocumentLists"],
  documentStatus: (documentId: string | null) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "documentStatus", documentId];
  },
  usersInOrg: () => {
    return [DOCUMENT_LISTS_MODULE_KEY, "usersInOrg"];
  },
  documentListPermissions: (documentListId: string) => {
    return [
      DOCUMENT_LISTS_MODULE_KEY,
      "documentListPermissions",
      documentListId,
    ];
  },
  nodePath: (documentListId: string | null, nodeId: string | null) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "nodePath", documentListId, nodeId];
  },
  selection: (documentListId: string | null, documentIds: string[] | null) => {
    return [
      DOCUMENT_LISTS_MODULE_KEY,
      "selection",
      documentListId,
      documentIds?.sort(),
    ];
  },
  indexingStatus: (documentListId: string | null | undefined) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "indexingStatus", documentListId];
  },
  filters: (documentListId: string | null | undefined) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "filters", documentListId];
  },
  filterOptions: (documentListId: string | null | undefined) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "filterOptions", documentListId];
  },
  documentMetadata: (documentListId: string | null | undefined) => {
    return [DOCUMENT_LISTS_MODULE_KEY, "documentMetadata", documentListId];
  },
};

// Typed reports key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
export type DocumentListQueryContext = QueryContextFromKeys<
  typeof documentListKeys
>;
