import { DocumentList } from "source/types/document-list/documentList.types";
import { useQueryDocumentLists } from "./useQueryDocumentLists";

// Get Quick Upload document list for a given user
export const useQuickUploadsDocumentList = () => {
  const { data } = useQueryDocumentLists();
  return data?.find(
    (docList: DocumentList) => docList.is_quick_upload === true
  );
};
