import React, { createContext, useContext, useState } from "react";
import { DocumentListGridApi } from "source/types/document-list/grid.types";

type DocumentListGridContextValue = {
  gridApi?: DocumentListGridApi;
  setGridApi: (api?: DocumentListGridApi) => void;
};

export const DocumentListGridContext =
  createContext<DocumentListGridContextValue>(
    {} as DocumentListGridContextValue
  );

type Props = { children: React.ReactNode };

export const useDocumentListGridApi = () => {
  return useContext(DocumentListGridContext).gridApi;
};

export const DocumentListGridContextProvider = ({ children }: Props) => {
  const [gridApi, setGridApi] = useState<DocumentListGridApi>();

  return (
    <DocumentListGridContext.Provider value={{ gridApi, setGridApi }}>
      {children}
    </DocumentListGridContext.Provider>
  );
};
