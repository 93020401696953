import { useQueryClient } from "@tanstack/react-query";
import { documentListKeys } from "../../api/document-list/documentListKeys";
import { useActiveDocumentListId } from "./useActiveDocumentListId";

export const useResetDocListQueryCache = () => {
  const queryClient = useQueryClient();
  const activeDocumentListId = useActiveDocumentListId();

  return async () => {
    // reset breadcrumb query cache
    queryClient.resetQueries({
      queryKey: documentListKeys
        .nodePath(activeDocumentListId, null)
        .filter(Boolean),
      exact: false,
    });
  };
};
